<template>
  <v-app>
    <Cabecera></Cabecera>
    <Menu></Menu>
    <v-main>
      <router-view :key="$route.fullPath"/>
    </v-main>
    <Pie></Pie>
    <!--

    ? Investigar cómo usarlo para cargas lentas
    ? en componentes específicos

    <VuePreloader
    background-color="#FFFFFF"
    color="#338A7A"
    transition-type="fade-up"
    :loading-speed="25"
    :transition-speed="1400"
    @loading-is-over="loadingIsOver"
    @transition-is-over="transitionIsOver">       
    </VuePreloader>
    -->
    
  </v-app>
</template>

<style>
@import './assets/css/general.css'; 
@import './assets/css/colores.css';   
@import './assets/css/fuentes.css';
@import './assets/css/categorias.css';
</style>

<script>



import Cabecera from '@/components/globales/Cabecera.vue'
import Menu from '@/components/globales/Menu.vue'
import Pie from '@/components/globales/Pie.vue'



export default {
  name: 'App',

  components :{
    Cabecera,
    Menu,
    Pie
  },

  watch: {
    $route: {
      immediate:true,
      handler(to,from) {
        const SITIO='Casamoya.cl instrumentos musicales'
        let tituloSitio=""

        if(to.params.categoriaProducto)
        {
          tituloSitio = to.params.categoriaProducto
        }

        if(to.params.name)
        {
          tituloSitio = to.params.name
        }

        document.title = tituloSitio + " | " + SITIO|| SITIO
        console.log("To")
        console.log(to)
      }
      
    }
  }, 

  data: () => ({
    //
  }),
};
</script>
