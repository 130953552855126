<template>
    <v-container fluid>
        <!-- <Carrusel></Carrusel> --> 
         <BreadCrumb></BreadCrumb>
        <GaleriaProducto
        :titulo=Titulo :categoriaProducto="categoriaProducto"></GaleriaProducto>
    </v-container>
</template>

<script>
import Carrusel from '@/components/pagina/Carrusel.vue'
import GaleriaProducto from '@/components/pagina/GaleriaProducto.vue'
import BreadCrumb from '@/components/globales/BreadCrumb.vue';


    export default {
        name:'Categoria', 

        props : {
            categoriaProducto:String
        },

        components : {
            Carrusel,
            GaleriaProducto,
            BreadCrumb
        }, 

        data() {
            return {
                Titulo:''
            }
        },

        created(){
            this.armaTitulo();
        }, 

        methods : {
            armaTitulo: function() {
                this.Titulo='Productos disponibles en ' + this.categoriaProducto
            }
        }
        
    }
</script>