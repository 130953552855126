import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Categoria from '@/views/Categoria.vue'
import Producto from '@/views/Producto.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  }, 
  {
    path: '/:categoriaProducto',
    name:'categoria',
    component: Categoria,
    props:true,
    replace:true
  },
  {
    path: '/:categoriaProducto/:nombreProducto',
    name:'producto',
    component: Producto,
    props:true,
    replace:true,
    alias: [
        '/:categoriaProducto/:categoriaProducto/:nombreProducto',
        '/:categoriaProducto/:categoriaProducto/:categoriaProducto/:nombreProducto',
        '/:categoriaProducto/:categoriaProducto/:categoriaProducto/:categoriaProducto/:nombreProducto'
        ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '',
  routes
})

export default router
