<template>
    <v-container fluid class="mb-5">
        <h1 class="verde_oscuro text-center">{{ titulo }}</h1>
        <v-row no-gutters>
            <v-col v-for="producto in dataProductos" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 mr-1 col-12 cajaGaleria">
                <v-card class="cajaProductoCategoria">
                    <a :href="producto.ruta">
                        <v-img
                            height="300px"
                            :src="producto.imagenDestacada"></v-img>
                        <v-card-title class="verde_oscuro nombreProductoCategoria">{{ producto.nombre }}</v-card-title>
                    </a>
                    <div class="my-4 text-subtitle-1 text-right mr-2 rosa">${{ producto.precio }}</div>

                   

                    <v-card-actions>
                        <v-btn
                            block
                            class="rosa_fondo blanco"
                            :to="producto.ruta"
                            @click="muestraEnlace(producto.ruta)"
                        >
                            Ver más
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'

export default {
    name:'GaleriaProducto',

    props:{
        titulo:String,
        categoriaProducto:String
    },

    data() {
        return {
            dataProductos:''
        }
    }, 

    created() {
        
        this.cargaProductos()
    },

    methods : {
        cargaProductos:function() {
            
           this.categoriaProducto 
           axios
            .get(process.env.VUE_APP_RUTA_API+ "/listaProductos?slug=" + this.categoriaProducto + "&cantidad=12")
            .then(response => {
            this.dataProductos=response.data.producto
            console.log(this.dataProductos)
                })
           .catch(function (error) {
            if (error.response) {
             // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
            })

        }, 

        muestraEnlace:function(enlace) {
            console.log(enlace)
        }
    }
}
</script>