<template>
    <v-container fluid>
        <h3 class="text-center blanco">{{titulo}}</h3>
        <v-list lines="one" class="verde_intermedio_fondo blanco">
            <v-list-item 
                v-for="menu in dataMenu"
                :key="menu.id"
            >
                <router-link :to="menu.url">
                    <span class="blanco">
                        {{ menu.nombre }}
                    </span>
                    
                </router-link>
            </v-list-item>
        </v-list>
    </v-container> 
</template>

<script>
import axios from 'axios'
export default {
    name:'menuVertical',

    props: {
        titulo:String,
        idMenu:String
    }, 
    data() {
        return {
            dataMenu: []
        }
    }, 

    created() {
        this.cargarMenu()
    },

    methods: {
        cargarMenu:function() {
           axios
            .get(process.env.VUE_APP_RUTA_API + "categoriaProductos")
            .then(response => {
            this.dataMenu=response.data
                })
           .catch(function (error) {
            if (error.response) {
             // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
            })

        }
    }
    
}
</script>