import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"mt-15",attrs:{"fluid":""}},[_c('div',{staticClass:"pie verde_intermedio_fondo blanco mt-5 text-center p-5"},[_c(VRow,[_c(VCol,{attrs:{"col-3":"","lg-3":"","md-3":""}},[_c(VCard,{staticClass:"verde_intermedio_fondo blanco text-left pa-3"},[_c(VRow,[_c(VCol,[_c('h2',{staticClass:"blanco text-center"},[_vm._v("¿Necesitas ayuda?")]),_c('br'),_c('span',{staticClass:"text-left"},[_vm._v("Ubícanos en el whastapp : "),_c('strong',{staticClass:"font-weight-black"},[_vm._v("+56936699469")])]),_c('br'),_c('span',{staticClass:"text-left"},[_vm._v("Escríbenos al correo : "),_c('a',{staticClass:"blanco font-weight-black enlace",attrs:{"href":"mailto:hola@casamoya.cl"}},[_vm._v("hola@casamoya.cl")])])])],1),_c('h2',{staticClass:"blanco mt-3 text-center"},[_vm._v("Encuéntranos en redes sociales")]),_c(VRow,{staticClass:"text-center"},[_c(VCol,[_c('a',{attrs:{"href":"https://www.facebook.com/casamoyacl/","target":"_blank"}},[_c(VImg,{attrs:{"src":require("@/assets/imagenes/facebook.png"),"max-width":"50px"}})],1)]),_c(VCol,[_c('a',{attrs:{"href":"https://www.instagram.com/casamoya.cl","target":"_blank"}},[_c(VImg,{attrs:{"src":require("@/assets/imagenes/instagram.png"),"max-width":"50px"}})],1)]),_c(VCol,[_c('a',{attrs:{"href":"https://www.tiktok.com/@casamoya.cl","target":"_blank"}},[_c(VImg,{attrs:{"src":require("@/assets/imagenes/tiktok.png"),"max-width":"50px"}})],1)])],1)],1),_c('br'),_c(VRow)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }