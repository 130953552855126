<template>
    <v-breadcrumbs :items="dataBreadcrumb" class="verde_oscuro">
        <template v-slot:item="{item}">
            <v-breadcrumbs-item
                :to="item.href"
                class="verde_oscuro">
                {{ item.text }}
            </v-breadcrumbs-item>
        </template>
      
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
</template>


<script>

export default {
    name:'BreadCrumb',
    data() {
        return {
            dataBreadcrumb: [],
        }
    },

    created() {
        this.obtenBreadcumb()
    },

    methods : {
        obtenBreadcumb : function (sabanaRutas) {
            const arregloRutas= this.$router.currentRoute.path.split("/")
            var rutasRecibidas= []
            rutasRecibidas.push(
                {
                text: 'Estás en : Inicio',
                disabled: false,
                href: '/'
                }
            )
            
            arregloRutas.forEach(function(ruta){
                if(ruta.length>0) {
                    rutasRecibidas.push({
                    text:ruta,
                    disabled:true,
                    href:ruta 
                })
                 }
                
            })

            this.dataBreadcrumb=rutasRecibidas
            console.log("Rutas recibidas")
            console.log(rutasRecibidas)
            }
        }
    
}

</script>