<template>
    <v-container fluid>
        <v-row>
            <v-col inline>
                Estás en <BreadCrumb></BreadCrumb>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h1>{{ objProducto.nombre }}</h1>
                <h2>{{ objProducto.sku }}</h2>
            </v-col>
            <v-col align="right">
                <v-img :src="objProducto.marca.logo" max-width="200px"></v-img>
               <!-- <span>Imagen de la marca</span> -->
            </v-col>
        </v-row>
       
        <v-row>
            <v-col class="col-7">
                <v-carousel
                    hide-delimiter-background
                    delimiter-icon="mdi-minus"
                    cycle
                    show-arrows>
                        <v-carousel-item 
                            v-for="imagen in objProducto.galeria"
                            :src="imagen"
                            cover
                            >

                        </v-carousel-item>
                    </v-carousel>
            </v-col>
            <v-col class="col-5">
                <h3 class="rosa precio">{{ objProducto.precio }}</h3>
                <span>{{ objProducto.descripcionCorta }}</span>
                <br />
                <br />
                <!--
                <v-container fluid>
                    <v-row>
                        <v-col outlined>
                            <span>Servicio 1</span>
                        </v-col>
                        <v-col outlined>
                            <span>Servicio 2</span>
                        </v-col>
                        <v-col outlined>
                            <span>Servicio 3</span>
                        </v-col>
                    </v-row>
                </v-container>
                -->
                <!-- <span>servicios adicionales</span> -->
                <br />
                <v-container fluid>
                    <h4 class="verde_intermedio mayusculas mb-3">
                        Información técnica del producto
                    </h4>
                    <v-row v-for="objAtributo in objProducto.atributos">
                        <v-col >
                            <h5 class="verde_intermedio">{{ objAtributo.nombre }}</h5>
                            <v-btn outlined class="verde_oscuro">
                                {{ objAtributo.valor }}
                            </v-btn> 
                        </v-col>
                    </v-row>
                </v-container>
                <!--
                <v-card>
                    <v-card-title>
                        ¿Tienes dudas sobre el producto?
                    </v-card-title>
                    <v-card-text>
                        Prueba
                    </v-card-text>
                </v-card>
                -->
                <br />
                <!--
                <v-row class="mt-3">
                    <v-col>
                        <v-btn class="verde_intermedio_fondo blanco">
                            Comprar producto
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn class="rosa_fondo blanco">
                            Agregar a mis favoritos
                        </v-btn>
                    </v-col>
                </v-row>
                -->
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mb-5">
                <h2 class="text-center verde_oscuro">Productos relacionados y accesorios</h2>
                <v-row>
                    <v-col v-for="objRelacionado in objProducto.productosRelacionados">
                        <v-card elevation="0" class="cajaProductoCategoria">
                            <v-img 
                            width="250"
                            :src=objRelacionado.imagenDestacada>
                            </v-img>
                            <v-card-title>{{ objRelacionado.nombre }}</v-card-title>
                            <v-card-text class="rosa text--center">
                                {{ objRelacionado.precio }}
                            </v-card-text>
                            <v-card-actions>
                                <v-btn 
                                block
                                class="verde_oscuro_fondo blanco"
                                :to="objRelacionado.ruta">
                                    Ver producto
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>Más información disponible sobre el producto</v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import BreadCrumb from '@/components/globales/BreadCrumb.vue'


export default {
    name:'Producto',

    components : {
        BreadCrumb
    },

    data() {
        return {
            objProducto:''
        }
    },

    created() {
        this.obtenProducto()
    },

    methods: {
        obtenProducto: function() {
            axios
            .get(process.env.VUE_APP_RUTA_API  + "/producto?slug=" + this.$route.params.nombreProducto
            )
            .then(response => {
            this.objProducto=response.data.producto
                })
           .catch(function (error) {
            if (error.response) {
             // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
            })
        }
    }
}
</script>