<template>
    <v-container fluid>
        <h1>{{ valor }}</h1>
        <Carrusel></Carrusel>
        <GaleriaProducto titulo="Ultimos Productos"></GaleriaProducto>
    </v-container>
</template>

<script>
import Carrusel from '@/components/pagina/Carrusel.vue'
import GaleriaProducto from '@/components/pagina/GaleriaProducto.vue'
    export default {
        name:'Home', 

        components : {
            Carrusel,
            GaleriaProducto
        },
        created() {
            console.log("Ruta -> " + process.env.VUE_APP_RUTA_API)
        }
    }
</script>