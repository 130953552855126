import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"mb-5",attrs:{"fluid":""}},[_c('h1',{staticClass:"verde_oscuro text-center"},[_vm._v(_vm._s(_vm.titulo))]),_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.dataProductos),function(producto){return _c(VCol,{staticClass:"col-lg-3 col-md-6 col-sm-12 col-xs-12 mr-1 col-12 cajaGaleria"},[_c(VCard,{staticClass:"cajaProductoCategoria"},[_c('a',{attrs:{"href":producto.ruta}},[_c(VImg,{attrs:{"height":"300px","src":producto.imagenDestacada}}),_c(VCardTitle,{staticClass:"verde_oscuro nombreProductoCategoria"},[_vm._v(_vm._s(producto.nombre))])],1),_c('div',{staticClass:"my-4 text-subtitle-1 text-right mr-2 rosa"},[_vm._v("$"+_vm._s(producto.precio))]),_c(VCardActions,[_c(VBtn,{staticClass:"rosa_fondo blanco",attrs:{"block":"","to":producto.ruta},on:{"click":function($event){return _vm.muestraEnlace(producto.ruta)}}},[_vm._v(" Ver más ")])],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }