<template>
    <v-app style="max-height:500px !important;">
        <v-container fluid>
            <v-carousel
            hide-delimiter-background
            cycle
            show-arrows>
                <v-carousel-item 
                    v-for="carrusel in dataCarrusel"
                    :src="carrusel.imagenDestacada"
                    cover
                    >

                </v-carousel-item>
            </v-carousel>
        </v-container>
    </v-app>
</template>

<script>
import axios from 'axios'
import {def} from '@/definiciones.js';

export default {
    name:'Carrusel',

    data() {
        return {
            dataCarrusel:''
        }
    }, 

    created() {
       this.obtenCarrusel()
    },

    methods: {
        obtenCarrusel:function() {
           axios
            .get(process.env.VUE_APP_RUTA_API + "/posts?categoria=carrusel")
            .then(response => {
            this.dataCarrusel=response.data.posts
                })
           .catch(function (error) {
            if (error.response) {
             // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
            })
        }
    }
}
</script>