<template>
    <v-container class="text-center">
        <v-flex class="text-center">
            <a href="/">

            <v-img 
            class="mx-auto"
            width="400px"
            src="@/assets/imagenes/logo.png">
           </v-img>
        </a>
        </v-flex>
    </v-container>
</template>

<script>
    export default {
        name: 'Cabecera'
    }
</script>